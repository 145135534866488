import React from "react"
import DynamicImage from "../DynamicImage/dynamic-image"
import css from "./gallery.module.scss"

const Gallery = ({ items }) => (
  <div className={css.gallery}>
    <div className={css.gallery__container}>
      {items &&
        items.map((item, index) => {
          const { name, imagePath } = item
          return (
            <div className={css.figure} key={index}>
              <DynamicImage alt={name} filename={imagePath} />
              <div className={css.imageLabel}>{name}</div>
            </div>
          )
        })}
    </div>
  </div>
)

export default Gallery
