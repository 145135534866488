import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import Gallery from "../../../components/Gallery/gallery"

const Books = () => (
  <Layout>
    <SEO title="Books" />
    <HeroInner title="Books" imageAlt="Books" imagePath="services/retail-products/books/hero.jpg" />
    <ArticleFullWidth
      descriptions={[
        `Established over 10 years ago, Blend-Divine has grown to become a leading wholesaler of books to retailers around the world.`,
        `We have thousands of books in a variety of categories from many publishers/resellers. Our inventory is comprised of remainders, returns and closeouts from the resellers. We offer New and Used Books which may be "remainder marked" by the publisher. `,
        `Automated order fulfillment equipment and over 50 dedicated staff ensure your orders are shipped quickly and your costs remain low. Blend-Divine offers the largest selection of deeply discounted books on the wholesale market worldwide, which means great titles on your shelves at a fraction of the cost.`,
        `These are the main book genres we offer:`,
      ]}
    />

    <Gallery
      items={[
        {
          name: "Action and Adventure",
          imagePath: "services/retail-products/books/action-and-adventure.jpg",
        },
        {
          name: "Classics",
          imagePath: "services/retail-products/books/classics.jpg",
        },
        {
          name: "Comic Book or Graphic Novel",
          imagePath: "services/retail-products/books/comic.jpg",
        },
        {
          name: "Detective and Mystery",
          imagePath: "services/retail-products/books/detective-and-mistery.jpg",
        },
        {
          name: "Fantasy",
          imagePath: "services/retail-products/books/fantasy.jpg",
        },
        {
          name: "Horror",
          imagePath: "services/retail-products/books/horror.jpg",
        },
        {
          name: "History",
          imagePath: "services/retail-products/books/history.jpg",
        },
        {
          name: "Romance",
          imagePath: "services/retail-products/books/romance.jpg",
        },
        {
          name: "Science Fiction (Sci-Fi)",
          imagePath: "services/retail-products/books/sifi.jpg",
        },
        {
          name: "Suspense and Thrillers",
          imagePath: "services/retail-products/books/suspence.jpg",
        },
        {
          name: "Biographies and Autobiographies",
          imagePath: "services/retail-products/books/biographies.jpg",
        },
        {
          name: "Educational Books",
          imagePath: "services/retail-products/books/educational.jpg",
        },
      ]}
    />
  </Layout>
)

export default Books
